<script setup lang="ts">
import type { Social } from 'profile/types/profile'
defineProps<{
  socials: Social[]
  linkToSocials?: boolean
  iconSize: number
  maxItems?: number
  itemContainerClass?: string
  iconContainerClass?: string
}>()

const { $formatToCompactNumber } = useNuxtApp()
</script>

<template>
  <div class="flex flex-wrap">
    <div
      v-for="social in maxItems ? socials.slice(0, maxItems) : socials"
      :key="social.id"
      :class="itemContainerClass"
    >
      <SocialIconLink
        v-if="social.id"
        :icon-size="iconSize"
        :platform-id="social.id.toLowerCase()"
        :url="linkToSocials ? social.url : undefined"
        :container-class="iconContainerClass"
      >
        <span v-if="social.followersCount">
          {{ $formatToCompactNumber(social.followersCount) }}
        </span>
      </SocialIconLink>
    </div>
  </div>
</template>
