<script setup lang="ts">
import type { ProductGridItem } from 'gig/types/product-grid-item'
import IconHeartDark from '@/assets/images/heart-dark.svg'
import IconHeartDarkSelected from '@/assets/images/heart-dark-selected.svg'
import IconReviewStar from '@/assets/images/review-star.svg'

const props = withDefaults(
  defineProps<{
    product: ProductGridItem
    isGigFavorite?: boolean
    intersectOnce?: boolean
  }>(),
  {
    isGigFavorite: false,
    intersectOnce: true,
  },
)

const itemElem = ref(null)
const { $parseReviewScore } = useNuxtApp()
const emit = defineEmits<{
  (e: 'intersecting', intersectionCount: number): void
  (e: 'updateFavoriteProduct', id: string): void
}>()

const intersectionCount = ref(0)
useIntersectionObserver(itemElem, ([{ isIntersecting }], observerElement) => {
  if (isIntersecting) {
    intersectionCount.value = ++intersectionCount.value
    emit('intersecting', intersectionCount.value)
    if (props.intersectOnce) {
      observerElement.disconnect()
    }
  }
})

const updateFavoriteProduct = (event: Event) => {
  event.preventDefault()
  emit('updateFavoriteProduct', props.product.id)
}
</script>

<template>
  <NuxtLink
    ref="itemElem"
    :to="product.link"
    class="relative flex flex-col overflow-hidden rounded-md border border-solid border-gray-400 text-gray-100 hover:shadow-hover focus:ring-1 focus:ring-blue-300"
    :aria-labelledby="`product-label-${product.id}`"
  >
    <button
      class="p-button p-button-icon-only p-button-text absolute right-0 m-1 !h-12 !w-12 border-0 !p-2.5 hover:!bg-transparent focus-visible:!bg-transparent [&:focus-visible_>svg]:fill-gray-400 [&:hover_>svg]:fill-gray-300"
      :aria-label="isGigFavorite ? 'Added to favorites' : 'Add to favorites'"
      @click="updateFavoriteProduct"
    >
      <IconHeartDarkSelected
        v-if="isGigFavorite"
        class="icon-heart"
        aria-hidden="true"
        width="27"
      />
      <IconHeartDark
        v-else
        class="icon-heart"
        aria-hidden="true"
        width="27"
      />
    </button>

    <div
      class="aspect-[342/335] min-w-[258px] shrink-0 bg-gray-200 sm:min-w-0 md:aspect-[268/335]"
    >
      <NuxtPicture
        v-if="product.image"
        loading="lazy"
        :width="360"
        :height="360"
        :img-attrs="{
          class: 'w-full h-full object-cover',
        }"
        :src="product.image"
        :alt="product.imageAlt"
      />
    </div>

    <div class="flex h-full flex-col justify-between bg-white p-3">
      <div class="mb-2 flex items-center justify-between text-sm">
        <BaseAvatar
          v-if="product.avatar || product.creatorName"
          size="small"
          :user-name="product.creatorName"
          :src="product.avatar"
          :alt="`avatar ${product.creatorName}`"
          class="mr-2"
        />

        <span class="mr-auto truncate">
          {{ product.creatorName }}
        </span>
        <div class="flex items-center gap-1">
          <IconReviewStar
            :class="{
              '!fill-blue-100 !stroke-blue-200': product.rating,
              'fill-white': !product.rating,
            }"
          />
          <span class="text-gray-200">
            {{ $parseReviewScore(product.rating) }}
          </span>
        </div>
      </div>

      <p
        class="product-name mb-2 line-clamp-2 w-full break-words text-base text-gray-200"
      >
        {{ product.name }}
      </p>

      <div class="mt-auto">
        <GigDetailsSocialCounterList
          v-if="product.socials.length > 0"
          :socials="product.socials"
          :icon-size="14"
          :max-items="3"
          class="mb-2 gap-1 text-xs font-semibold leading-[14px]"
          item-container-class="rounded-[30px] border border-gray-400 py-[5px] px-2"
          icon-container-class="gap-1"
        />
        <div class="text-xl font-medium">From {{ product.price }}</div>
      </div>

      <span
        :id="`product-label-${product.id}`"
        class="sr-only"
      >
        Collab {{ product.name }} by {{ product.creatorName }}, price: from
        {{ product.price }}
      </span>
    </div>
  </NuxtLink>
</template>

<style scoped>
.icon-heart {
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.5));
}
.product-name {
  word-break: break-word;
}
</style>
